import React, { useState } from 'react';
import './App.css';

function App() {
  const [lightMode, setLightMode] = useState(false);

  const toggleLightMode = () => {
    setLightMode(!lightMode);
  };

  return (
    <div className={`App ${lightMode ? 'light-mode' : ''}`}>
      <header className="App-header">
        <h1>Fuck you!</h1>
        <button className="light-mode-button" onClick={toggleLightMode}>
          fuck you in {lightMode ? 'dark' : 'light'} mode
        </button>
      </header>
    </div>
  );
}

export default App;
